import platform from "platform";

/**
 * @deprecated Please use `react-device-detect` instead.
 */
export const isMobileDevice = () => window.MOBILE_DEVICE;

/**
 * @deprecated Please use `react-device-detect` instead.
 */
export const getOS = () => {
  const os = ["Windows", "Linux", "Macintosh"];
  const osName = os.find(v => navigator.appVersion.indexOf(v) >= 0);
  return osName ? osName : "Other";
};

/**
 * @deprecated Please use `react-device-detect` instead.
 */
export const parseUserAgent = () => {
  const { name, version, os, description, product, layout, manufacturer, ua } =
    platform;

  // Note: making this redundant on purpose
  // to showcase available data.
  return {
    name,
    version,
    os,
    description,
    product,
    layout,
    manufacturer,
    ua,
  };
};

/**
 * @deprecated Please use `react-device-detect` instead.
 */
export const isChromiumBased = () => !!window.chrome;

/**
 * @deprecated Please use `react-device-detect` instead.
 */
export const isSafari = () => parseUserAgent().name === "Safari";

/**
 * @deprecated Please use `react-device-detect` instead.
 */
export const isIphone = () => parseUserAgent().product === "iPhone";

/**
 * @deprecated Please use `react-device-detect` instead.
 */
export const isAndroid = () => parseUserAgent().os?.family === "Android";

/**
 * @deprecated Please use `react-device-detect` instead.
 */
export const isMobileOs = () =>
  ["iOS", "Android"].includes(parseUserAgent().os?.family);

export const isIntendedOnNewTab = event => {
  const isCtrlPressed = event.ctrlKey || event.metaKey;
  const isMiddleMouseClick = event.button && event.button === 1;

  return isCtrlPressed || isMiddleMouseClick;
};
