import { t } from "i18n-js";
import type { TaxIdType } from "./TaxIdType";

export const others: TaxIdType[] = [
  {
    countryCode: "AU",
    country: t("tax_ids.australia"),
    label: t("tax_ids.australia_abn"),
    example: "12345678912",
    description: t("tax_ids.australia_abn_description"),
    enum: "au_abn",
  },
  {
    countryCode: "AU",
    country: t("tax_ids.australia"),
    label: t("tax_ids.australia_arn"),
    example: "123456789123",
    description: t("tax_ids.australia_arn_description"),
    enum: "au_arn",
  },
  {
    countryCode: "EG",
    country: t("tax_ids.egypt"),
    label: t("tax_ids.egypt_tin"),
    example: "123456789",
    description: t("tax_ids.egypt_tin_description"),
    enum: "eg_tin",
  },
  {
    countryCode: "GE",
    country: t("tax_ids.georgia"),
    label: t("tax_ids.georgia_vat"),
    example: "123456789",
    description: t("tax_ids.georgia_vat_description"),
    enum: "ge_vat",
  },
  {
    countryCode: "IN",
    country: t("tax_ids.india"),
    label: t("tax_ids.india_gst"),
    example: "12ABCDE3456FGZH",
    description: t("tax_ids.india_gst_description"),
    enum: "in_gst",
  },
  {
    countryCode: "ID",
    country: t("tax_ids.indonesia"),
    label: t("tax_ids.indonesia_npwp"),
    example: "12.345.678.9-012.345",
    description: t("tax_ids.indonesia_npwp_description"),
    enum: "id_npwp",
  },
  {
    countryCode: "IL",
    country: t("tax_ids.israel"),
    label: t("tax_ids.israel_vat"),
    example: "000012345",
    description: t("tax_ids.israel_vat_description"),
    enum: "il_vat",
  },
  {
    countryCode: "JP",
    country: t("tax_ids.japan"),
    label: t("tax_ids.japan_cn"),
    example: "1234567891234",
    description: t("tax_ids.japan_cn_description"),
    enum: "jp_cn",
  },
  {
    countryCode: "JP",
    country: t("tax_ids.japan"),
    label: t("tax_ids.japan_rn"),
    example: "12345",
    description: t("tax_ids.japan_rn_description"),
    enum: "jp_rn",
  },
  {
    countryCode: "JP",
    country: t("tax_ids.japan"),
    label: t("tax_ids.japan_trn"),
    example: "T1234567891234",
    description: t("tax_ids.japan_trn_description"),
    enum: "jp_trn",
  },
  {
    countryCode: "KE",
    country: t("tax_ids.kenya"),
    label: t("tax_ids.kenya_pin"),
    example: "P000111111A",
    description: t("tax_ids.kenya_pin_description"),
    enum: "ke_pin",
  },
  {
    countryCode: "LI",
    country: t("tax_ids.liechtenstein"),
    label: t("tax_ids.liechtenstein_uid"),
    example: "CHE123456789",
    description: t("tax_ids.liechtenstein_uid_description"),
    enum: "li_uid",
  },
  {
    countryCode: "MY",
    country: t("tax_ids.malaysia"),
    label: t("tax_ids.malaysia_frp"),
    example: "12345678",
    description: t("tax_ids.malaysia_frp_description"),
    enum: "my_frp",
  },
  {
    countryCode: "MY",
    country: t("tax_ids.malaysia"),
    label: t("tax_ids.malaysia_itn"),
    example: "C 1234567890",
    description: t("tax_ids.malaysia_itn_description"),
    enum: "my_itn",
  },
  {
    countryCode: "MY",
    country: t("tax_ids.malaysia"),
    label: t("tax_ids.malaysia_sst"),
    example: "A12-3456-78912345",
    description: t("tax_ids.malaysia_sst_description"),
    enum: "my_sst",
  },
  {
    countryCode: "NZ",
    country: t("tax_ids.new_zealand"),
    label: t("tax_ids.new_zealand_gst"),
    example: "123456789",
    description: t("tax_ids.new_zealand_gst_description"),
    enum: "nz_gst",
  },
  {
    countryCode: "PH",
    country: t("tax_ids.philippines"),
    label: t("tax_ids.philippines_tin"),
    example: "123456789012",
    description: t("tax_ids.philippines_tin_description"),
    enum: "ph_tin",
  },
  {
    countryCode: "RU",
    country: t("tax_ids.russia"),
    label: t("tax_ids.russia_inn"),
    example: "1234567891",
    description: t("tax_ids.russia_inn_description"),
    enum: "ru_inn",
  },
  {
    countryCode: "RU",
    country: t("tax_ids.russia"),
    label: t("tax_ids.russia_kpp"),
    example: "123456789",
    description: t("tax_ids.russia_kpp_description"),
    enum: "ru_kpp",
  },
  {
    countryCode: "SA",
    country: t("tax_ids.saudi_arabia"),
    label: t("tax_ids.saudi_arabia_vat"),
    example: "123456789012345",
    description: t("tax_ids.saudi_arabia_vat_description"),
    enum: "sa_vat",
  },
  {
    countryCode: "SG",
    country: t("tax_ids.singapore"),
    label: t("tax_ids.singapore_gst"),
    example: "M12345678X",
    description: t("tax_ids.singapore_gst_description"),
    enum: "sg_gst",
  },
  {
    countryCode: "SG",
    country: t("tax_ids.singapore"),
    label: t("tax_ids.singapore_uen"),
    example: "123456789F",
    description: t("tax_ids.singapore_uen_description"),
    enum: "sg_uen",
  },
  {
    countryCode: "SI",
    country: t("tax_ids.slovenia"),
    label: t("tax_ids.slovenia_tin"),
    example: "SI123456789",
    description: t("tax_ids.slovenia_tin_description"),
    enum: "si_tin",
  },
  {
    countryCode: "ZA",
    country: t("tax_ids.south_africa"),
    label: t("tax_ids.south_africa_vat"),
    example: "4123456789",
    description: t("tax_ids.south_africa_vat_description"),
    enum: "za_vat",
  },
  {
    countryCode: "KR",
    country: t("tax_ids.south_korea"),
    label: t("tax_ids.south_korea_brn"),
    example: "123-45-67890",
    description: t("tax_ids.korean_brn_description"),
    enum: "kr_brn",
  },
  {
    countryCode: "TW",
    country: t("tax_ids.taiwan"),
    label: t("tax_ids.taiwan_vat"),
    example: "12345678",
    description: t("tax_ids.taiwan_vat_description"),
    enum: "tw_vat",
  },
  {
    countryCode: "TH",
    country: t("tax_ids.thailand"),
    label: t("tax_ids.thailand_vat"),
    example: "1234567891234",
    description: t("tax_ids.thailand_vat_description"),
    enum: "th_vat",
  },
  {
    countryCode: "TR",
    country: t("tax_ids.turkey"),
    label: t("tax_ids.turkey_tin"),
    example: "0123456789",
    description: t("tax_ids.turkey_tin_description"),
    enum: "tr_tin",
  },
  {
    countryCode: "UA",
    country: t("tax_ids.ukraine"),
    label: t("tax_ids.ukraine_vat"),
    example: "123456789",
    description: t("tax_ids.ukraine_vat_description"),
    enum: "ua_vat",
  },
  {
    countryCode: "AE",
    country: t("tax_ids.united_arab_emirates"),
    label: t("tax_ids.united_arab_emirates_trn"),
    example: "123456789012345",
    description: t("tax_ids.united_arab_emirates_trn_description"),
    enum: "ae_trn",
  },
];
