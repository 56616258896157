import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { Loader } from "@circle-react-uikit/Loader";
import "./styles.scss";

export const CustomUpload = ({
  url,
  isLoading,
  onClickChange,
  onClickDelete,
}) => (
  <div className="form-meta profile-image">
    <div>
      <label htmlFor="avatar">{t("profile_photo")}</label>
      <div className="avatar-description">
        {t("new_profile_photo_description")}
      </div>
      {url && (
        <div className="action-btn-wrapper">
          <Button variant="secondary" onClick={onClickChange} type="button">
            {t("change_photo")}
          </Button>
          <Button variant="secondary" onClick={onClickDelete} type="button">
            {t("remove_cover_image")}
          </Button>
        </div>
      )}
    </div>
    {isLoading ? (
      <Loader center />
    ) : url ? (
      <img loading="lazy" src={url} alt="" className="image-uploader" />
    ) : (
      <Button variant="secondary" onClick={onClickChange} type="button">
        {t("upload_a_photo")}
      </Button>
    )}
  </div>
);
