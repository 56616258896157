import { t } from "i18n-js";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  reactQueryGet,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { PROFILE_INFO_QUERY_KEY } from "@circle-react/hooks/profile/useProfileInfo";
import { useToast } from "@circle-react-uikit/ToastV2";
import { getProfileFieldDetailQueryKey } from "./useProfileFieldDetail";
import { processBackendResponse as processListResponse } from "./useProfileFields";

export const PAGE_PROFILE_FIELD_KEY = "page-profile-fields";

const processBackendResponse = data => {
  const {
    visible_profile_fields: visibleFields,
    hidden_profile_fields: hiddenFields,
  } = data;
  return {
    visibleFields: processListResponse(visibleFields),
    hiddenFields: processListResponse(hiddenFields),
  };
};

/**
 * This is specific for each page
 * split into hidden and visible sections
 */
export const usePageProfileFields = ({ currentPage }) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const queryKey = [PAGE_PROFILE_FIELD_KEY, currentPage];
  const getOptions = useQuery(queryKey, async () => {
    const response = await reactQueryGet(
      internalApi.profileFields.pageIndex(currentPage),
    );
    if (!response) return;
    return processBackendResponse(response);
  });

  const reorderFields = useMutation(
    ({ reorderedProfileFields, hideProfileField, unhideProfileField }) =>
      reactQueryPost(internalApi.profileFields.pageFieldReorder(), {
        page_name: currentPage,
        reordered_profile_fields: reorderedProfileFields?.map(({ id }) => id),
        hide_profile_field_id: hideProfileField?.id,
        unhide_profile_field_id: unhideProfileField?.id,
      }),
    {
      onError: () => {
        toast.error(t("something_went_wrong"));
      },
      onSuccess: data => {
        queryClient.setQueryData(queryKey, processBackendResponse(data));
        queryClient.invalidateQueries([getProfileFieldDetailQueryKey()]);
        queryClient.removeQueries(PROFILE_INFO_QUERY_KEY);
      },
    },
  );

  return {
    getOptions,
    reorderFields,
  };
};
