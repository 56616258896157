import I18n from "i18n-js";
import translation from "../i18n-js/en.json";
import translationPt from "../i18n-js/pt.json";

I18n.translations = { pt: translationPt.pt, en: translation.en };
// https://github.com/fnando/i18n-js/issues/525
I18n.fallbacks = true;
I18n.enableFallback = true;
I18n.defaultLocale = "en";
I18n.locale = (() => window.locale || "en")();
export const t = (...args) => I18n.t(...args);

export default I18n;
