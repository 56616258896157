import { t } from "i18n-js";
import { isString } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { reactQueryDelete } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { ARCHIVED_PROFILE_FIELDS_LIST_KEY } from "./useArchivedFields";

export const useDeleteField = () => {
  const queryClient = useQueryClient();
  const toastr = useToast();

  return useMutation(
    ({ id }) => reactQueryDelete(internalApi.profileFields.detail(id)),
    {
      onSuccess: () => {
        toastr.success(t("profile_fields.deleted"));
        queryClient.invalidateQueries({
          queryKey: ARCHIVED_PROFILE_FIELDS_LIST_KEY,
        });
      },
      onError: err => {
        const { errorDetails, message } = err;
        if (isString(errorDetails)) {
          toastr.error(errorDetails);
          return;
        }
        toastr.error(message ?? t("something_went_wrong"));
      },
    },
  );
};
