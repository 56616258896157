import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { DISPLAY_OPTION_FIELD_INPUTS } from "@circle-react-shared/ProfileFields/constants";
import {
  DEFAULT_FIELD_INPUTS,
  FIELD_TYPES,
  FIELD_TYPE_SPECIFIC_INPUTS,
  PAGES_ATTRIBUTES,
} from "../constants";

export const getProfileFieldDetailQueryKey = fieldId => {
  const key = "PROFILE_FIELD_DETAIL";
  if (fieldId) {
    return [key, fieldId];
  }
  return [key];
};

const getProfileFieldPages = (pages = []) => {
  const fieldNames = Object.values(DISPLAY_OPTION_FIELD_INPUTS);
  return fieldNames.map(fieldName => {
    const page = pages.find(page => page.name === fieldName);
    if (page) {
      return {
        ...page,
        visible: page.visible,
      };
    }
    return {
      name: fieldName,
      visible: true,
    };
  });
};

export const processFieldType = response => ({
  ...response,
  /* Backend has different names for these on GET vs UPDATE APIs */
  [FIELD_TYPE_SPECIFIC_INPUTS[FIELD_TYPES.SELECT].CHOICES_ATTRIBUTES]:
    response.choices,
  [FIELD_TYPE_SPECIFIC_INPUTS[FIELD_TYPES.NUMBER].NUMBER_OPTIONS]:
    response.number_options,
  [PAGES_ATTRIBUTES]: getProfileFieldPages(response.pages),
  [DEFAULT_FIELD_INPUTS.HIDDEN]: response.pages?.every(
    ({ visible }) => !visible,
  ),
});

export const useProfileFieldDetail = ({ fieldId }) => {
  const getOptions = useQuery(
    getProfileFieldDetailQueryKey(fieldId),
    async () => reactQueryGet(internalApi.profileFields.detail(fieldId)),
    {
      select: data => processFieldType(data),
      enabled: !!fieldId,
    },
  );

  return {
    getOptions,
  };
};
