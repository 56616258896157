import { t } from "@circle-react/custom_i18n";

export const supportedLocales = ["en", "pt"] as const;

export const supportedLocalesOptions = supportedLocales.map(locale => ({
  label: t(`supported_locales.locale_name.${locale}`),
  value: locale,
}));

export const defaultLocale = "en";
