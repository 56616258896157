import { useQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const useCurrentPlanApi = (forceFromSubscription = false) => {
  const {
    data: currentPlan,
    isLoading: isCurrentPlanLoading,
    error: errorFecthingCurrentPlan,
    refetch: refetchCurrentPlan,
    isRefetching: isRefetchingCurrentPlan,
  } = useQuery(
    ["community-current-plan"],
    () =>
      reactQueryGet(
        internalApi.communities.plans.show({
          forceFromSubscription,
        }),
      ),
    {
      retry: 10,
    },
  );

  return {
    currentPlan,
    isCurrentPlanLoading,
    errorFecthingCurrentPlan,
    refetchCurrentPlan,
    isRefetchingCurrentPlan,
  };
};
