import { useEffect, useMemo } from "react";
import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { memberProfileModalPaths } from "@circle-react/helpers/urlHelpers";
import { useRedirectBack } from "@circle-react/hooks/useRedirectBack";

export const useMemberProfileModal = () => {
  const { redirectBack } = useRedirectBack();

  const {
    currentCommunityMember,
    currentUser,
    isLoading: isLoadingPunditContext,
  } = usePunditUserContext();

  const {
    can_access_billing: shouldShowBilling,
    can_access_authentication_page: shouldShowAuthenticationTab,
    can_show_paywalls_affiliate: canShowPaywallsAffiliate,
  } = currentCommunityMember?.policies || {};

  useEffect(() => {
    if (!isLoadingPunditContext) {
      if (!currentUser) {
        redirectBack(window.location.href);
      } else if (!currentCommunityMember) {
        redirectBack();
      }
    }
  }, [
    redirectBack,
    isLoadingPunditContext,
    currentCommunityMember,
    currentUser,
  ]);

  const sidebarNavItems = useMemo(
    () => [
      {
        label: t("member_profile_modal.nav.profile"),
        href: memberProfileModalPaths.profile,
      },
      {
        label: t("member_profile_modal.nav.notifications"),
        href: memberProfileModalPaths.notifications,
      },
      ...(shouldShowAuthenticationTab
        ? [
            {
              label: t("member_profile_modal.nav.authentication"),
              href: memberProfileModalPaths.authentication,
            },
          ]
        : []),
      ...(shouldShowBilling
        ? [
            {
              label: t("member_profile_modal.nav.billing"),
              href: memberProfileModalPaths.billing,
            },
          ]
        : []),
      ...(canShowPaywallsAffiliate
        ? [
            {
              label: t("member_profile_modal.affiliate_dashboard.title"),
              href: memberProfileModalPaths.affiliate,
            },
          ]
        : []),
    ],
    [shouldShowAuthenticationTab, shouldShowBilling, canShowPaywallsAffiliate],
  );

  const handleClose = () => {
    window.webview?.onCloseMemberProfileModal?.();
    redirectBack();
  };

  return {
    shouldShowAuthenticationTab,
    shouldShowBilling,
    canShowPaywallsAffiliate,
    handleClose,
    sidebarNavItems,
    isLoadingPunditContext,
  };
};
